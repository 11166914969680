import React from 'react'
import Layout from '../components/indexLayout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const ZuMeinerPersonPage = props => (
  <Layout>
    <div className="page">
      <div className="container">
        <section className="hero-section2">
          <Img
            fluid={props.data.imageTwo.childImageSharp.fluid}
            className="hero-image"
            alt="hero image"
          />
          <div className="hero-texts">
            <p className="hero-header">Zu meiner Person</p>
            <div className="dash" />
            <p>
              Sehr geehrte Besucher,<br /><br />
              ich freue mich, Sie auf meiner Seite von Vogl Immobilien Lüdenscheid begrüßen zu können.<br /><br />
              Kurz zu meiner Person:<br />
              Mein Name ist Frank Vogl. Ich bin in Lüdenscheid geboren, 53 Jahre alt und Vater von zwei Töchtern.
              Meine Hobbys sind Lesen, Laufen und Reisen.
              <br /><br />
              Als gelernter Immobilienkaufmann unterstütze ich Sie sehr gerne beim Verkauf oder der Vermietung Ihrer Immobilie,
              aber auch bei der Suche nach einer Wohnung oder einem Haus, ob zur Miete oder als Eigentum.
              Für Fragen oder Informationen stehe ich Ihnen gerne zur Verfügung.
              <br /><br />
              Mit freundlichem Gruß
              <br />
              Frank Vogl
            </p>
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

//export const firstSiteImages = graphql`
//  fragment firstSiteImages on File {
//    childImageSharp {
//      fluid(maxWidth: 1000) {
//        ...GatsbyImageSharpFluid_tracedSVG
//      }
//    }
//  }
//`

export const firstSiteImages200 = graphql`
fragment firstSiteImages200 on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid_tracedSVG
    }
  }
}
`
//...firstSiteImages200
export const pageQuery = graphql`
  query {
    imageTwo: file(relativePath: { eq: "images/vogl-immobilien_frank_transparent2.png" }) {
      ...firstSiteImages200
    }
  }
`

export default ZuMeinerPersonPage

/*
  file(relativePath: {eq: "images/vogl-immobilien_frank_transparent.png"}) {
    childImageSharp {
      fixed {
        tracedSVG
*/